import React from 'react';
 
class Youtube extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: []
      };
    }
  
    componentDidMount() {
      fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&key=AIzaSyCkDQRJPK9DCU0O0XOsUcg4UH_Fo5fHqO4&playlistId=PLTpKDeGTX8gbwpA91z0ockv2O5mmFoB37&maxResults=4")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result.items
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
          <section className='features'>
            {items.map(item => (
                <article key={item.name}>
                <a href={"https://www.youtube.com/watch?v=" + item.snippet.resourceId.videoId} className="image" target="_blank">
                <img src={item.snippet.thumbnails.high.url} alt="" />
                </a>
                <h3 className="major">{item.snippet.title}</h3>
               
                <a href={"https://www.youtube.com/watch?v=" + item.snippet.resourceId.videoId} className="special" target="_blank">
                Watch
                </a>
                </article>
            ))}
          </section>
        );
      }
    }
  }
 
export default Youtube;