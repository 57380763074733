import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Youtube from '../components/Youtube';

import pic1 from '../assets/images/download.png';
import code from '../assets/images/code.png';
import wbap from '../assets/images/wbap2.png';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import drone1 from '../assets/images/drone1.png';
import drone2 from '../assets/images/drone2.png';
import drone3 from '../assets/images/drone3.png';
import pic7 from '../assets/images/pic07.jpg';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <span className="icon fa-gears"></span>
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">About Me</h2>
            <p>
              I am a full stack web developer from Southampton, UK. I have a
              vast amount of knowledge and experience in a variety of different
              technologies.
            </p>
            <Link to="/About" className="special">
              Learn More
            </Link>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={code} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Self Taught</h2>
            <p>
              I start my career as a web developer from a hobby, eventually
              building up my skills to be able to do it full time.
            </p>
            <a href="/About" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={wbap} alt="" />
          </a>
          <div className="content">
            <h2 className="major">What Am I Doing Now?</h2>
            <p>
              I currently work for an investment company called Ridown, they
              invest in business ideas and I work on webuyanyphone.com, which I
              throughly enjoy.
            </p>
            <a href="/About" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>
  </section>
  </Layout>
);

export default IndexPage;
